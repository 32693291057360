import React, {useCallback, useState} from "react";
import VideoPickerForm from "components/Organisms/VideoPickerForm";
import UploadVideoAndMetadata from "components/Organisms/UploadVideoAndMetadata";
import {useUser} from "hooks/useUser";
import VideoUploadSuccess from "components/Organisms/VideoUploadSuccess";

import {UploadStep} from "./types";

export function useUpload() {
  const [step, setStep] = useState<UploadStep>(UploadStep.Upload);
  const [file, setFile] = useState<File | null>(null);
  const {user} = useUser();

  const handleBack = useCallback(() => {
    setStep(UploadStep.Upload);
    setFile(null);
  }, []);

  const handleFinish = useCallback(() => {
    setStep(UploadStep.Success);
    setFile(null);
  }, []);

  const uploadFormOnSuccess = useCallback((newFile: File) => {
    setStep(UploadStep.Details);
    setFile(newFile);
  }, []);

  const renderStep = useCallback(() => {
    switch (step) {
      case UploadStep.Upload:
        return <VideoPickerForm user={user} onSuccess={uploadFormOnSuccess} />;

      case UploadStep.Details:
        return (
          <UploadVideoAndMetadata
            onBack={handleBack}
            onSuccess={handleFinish}
            uuid={user?.uuid}
            video={file!}
          />
        );

      case UploadStep.Success:
        return <VideoUploadSuccess uploadAnotherVideoOnClick={handleBack} />;

      default:
        return <VideoPickerForm user={user} onSuccess={uploadFormOnSuccess} />;
    }
  }, [file, handleBack, handleFinish, step, user, uploadFormOnSuccess]);

  return {renderStep, setStep, step};
}
