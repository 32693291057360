export enum BorderType {
  none,
  black
}

export interface AvatarProps {
  avatarURL?: string;
  borderType?: BorderType;
  size: number;
  username?: string;
}

export interface UseAvatarProps {
  avatarURL?: string;
  username?: string;
}

export interface AvatarContainerProps {
  size: number;
  borderType?: BorderType;
}
