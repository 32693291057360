import React from "react";
import {strings} from "config/strings";
import {TextTypes} from "components/Atoms/Text/types";
import Text from "components/Atoms/Text";

import {
  Container,
  Bar,
  Progress,
  Description,
  FileTitle,
  FileName
} from "./styles";

export type Props = {
  filename?: string;
  percent: number;
};

export const ProgressBar: React.FC<Props> = ({percent: p, filename}: Props) => {
  const percent = Math.round(p);

  return (
    <Container>
      <Description>
        <FileTitle textType={TextTypes.SubHeadings.SH3}>
          {strings.generic.fileName}:
        </FileTitle>
        <FileName textType={TextTypes.Body}>{filename ?? ""}</FileName>
        <Text textType={TextTypes.Headings.H5}>{percent}%</Text>
      </Description>
      <Bar>
        <Progress percent={percent} />
      </Bar>
    </Container>
  );
};
