import styled from "styled-components";
import Text from "components/Atoms/Text";
import {accentColor, textGray} from "config/styleVars";

type ProgressProps = {
  percent: number;
};

export const Container = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Bar = styled.div`
  background-color: rgba(255, 255, 255, 0.2);
  width: 100%;
  height: 7px;
  position: relative;
  border-radius: 4px;
  display: block;
  margin: 0 auto;
  overflow: hidden;
`;

export const Progress = styled.div<ProgressProps>`
  width: ${(props) => props.percent}%;
  height: 7px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${accentColor};
  border-radius: 4px;
  transition: width 100ms ease;
`;

export const Description = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  margin-bottom: 4px;
`;

export const FileTitle = styled(Text)`
  white-space: pre;
`;
export const FileName = styled(Text)`
  margin: 0 8px;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${textGray};
`;
