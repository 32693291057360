import styled from "styled-components";
import {colors} from "theme/colors";
import TextInputCounter from "components/Atoms/TextInputCounter";
import Text from "components/Atoms/Text";

import {
  InputBorderType,
  InputProps,
  StateProps,
  TextInputContainerProps
} from "./types";

export const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputContainer = styled.div<TextInputContainerProps>`
  background-color: ${({backgroundColor}) =>
    backgroundColor || colors.primary.black};
  ${(props) =>
    props.borderType === InputBorderType.underlined
      ? "border: none;"
      : "border: 1px solid;"}
  border-bottom: 1px solid;
  border-radius: ${(props) =>
    props.borderType === InputBorderType.underlined ? "0px" : "8px"};
  border-color: ${(props) => {
    const {isDisabled, isFocused, isInvalid, isSucceeded} = props;
    if (isDisabled) {
      return colors.primary.darkGrey;
    }

    if (isInvalid) {
      return colors.secondary.error;
    }

    if (isSucceeded) {
      return colors.secondary.success;
    }

    if (isFocused) {
      return colors.primary.cyan;
    }

    return colors.primary.darkGrey;
  }};
  padding-left: 8px;
  padding-right: 8px;
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto;
  overflow: hidden;
`;

export const InputAndLabelContainer = styled.div<{label?: string}>`
  padding-top: ${(props) => (props.label ? "18" : "8")}px;
  padding-bottom: ${(props) => (props.label ? "6" : "8")}px;
  position: relative;
  display: flex;
`;

export const Input = styled.input<InputProps>`
  width: 100%;
  border: none;
  background: transparent;
  appearance: none;
  box-sizing: border-box;
  outline: none;
  padding: 0px;

  font-family: ${(props) => props.fontFamily};
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  line-height: ${(props) => props.lineHeight}px;

  color: ${(props) =>
    props.isDisabled ? colors.primary.mediumGrey : colors.primary.white};

  &::placeholder {
    color: ${(props) =>
      props.label ? "transparent" : colors.primary.lightGrey};
  }

  &:focus::placeholder {
    color: ${colors.primary.lightGrey};
  }
`;

export const RightSideContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  margin-left: 12px;
`;

export const LeftSideContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
`;

export const Counter = styled(TextInputCounter)`
  grid-row: 2;
  margin-bottom: 4px;
`;

export const IconContainer = styled.div`
  grid-row: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HelperText = styled(Text)<StateProps>`
  color: ${(props) => {
    const {isDisabled, isInvalid, isSucceeded} = props;
    if (isDisabled) {
      return colors.primary.mediumGrey;
    }

    if (isInvalid) {
      return colors.secondary.error;
    }

    if (isSucceeded) {
      return colors.secondary.success;
    }

    return colors.primary.lightGrey;
  }};
  margin-top: 4px;
  margin-left: 13px;
`;
