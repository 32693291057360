import {useFormContext} from "react-hook-form";
import {useCallback, useEffect} from "react";
import {useDropzone} from "react-dropzone";
import {DropzoneConfig} from "./types";

export function useDropzoneHook(config: DropzoneConfig) {
  const {accept, name} = config;

  const formContext = useFormContext();
  if (!formContext)
    throw new Error("This component should be used inside a form context");
  const {register, unregister, setValue, watch} = formContext;
  const file = watch(name) as File;

  const onDrop = useCallback(
    (droppedFiles, rejectedFiles) => {
      if (rejectedFiles && rejectedFiles[0]?.file) {
        setValue(name, null, {
          shouldValidate: true
        });
        return;
      }

      setValue(name, droppedFiles[0], {
        shouldValidate: true
      });
    },
    [setValue, name]
  );

  const {getInputProps, getRootProps} = useDropzone({
    multiple: false,
    maxFiles: 1,
    onDrop,
    accept
  });

  useEffect(() => {
    register(name);
    return () => {
      unregister(name);
    };
  }, [register, unregister, name]);

  return {file, getInputProps, getRootProps};
}
