import styled from "styled-components";
import Text from "components/Atoms/Text";
import {colors} from "theme/colors";

import {
  ButtonContainerProps,
  ButtonSize,
  ButtonType,
  IconContainerProps,
  IconSide
} from "./types";

export const StyledText = styled(Text)`
  grid-column: 2;
  pointer-events: none;
`;

export const IconContainer = styled.div<IconContainerProps>`
  ${(props) => {
    if (!props.text) {
      return "";
    }

    if (props.iconSide === IconSide.right) {
      return "margin-left: 8px;";
    }

    return "margin-right: 8px;";
  }}
  grid-column: ${(props) => (props.iconSide === IconSide.right ? "3" : "1")};
  grid-row: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

export const RootContainer = styled.button<ButtonContainerProps>`
  type: button;
  display: grid;
  align-items: center;
  grid-template-columns: auto auto auto;
  background-color: ${(props) =>
    props.buttonType === ButtonType.primary
      ? colors.primary.white
      : colors.primary.black};
  color: ${(props) => {
    switch (props.buttonType) {
      case ButtonType.primary:
        return colors.primary.black;

      case ButtonType.secondary:
        return colors.primary.white;

      default:
        return colors.primary.white;
    }
  }};
  border-style: solid;
  border-color: ${colors.primary.white};
  border-width: ${(props) =>
    props.buttonType === ButtonType.secondary ? 1 : 0}px;
  border-radius: 4px;

  cursor: pointer;
  padding: 8px ${(props) =>
    props.buttonSize === ButtonSize.s || !props.text ? 8 : 16}px;
  width: auto;
  user-select: none;

  &:hover:enabled {
    border-width: ${(props) => {
      if (props.buttonType !== ButtonType.secondary) {
        return "0px";
      }

      if (props.buttonSize === ButtonSize.s) {
        return "1.5px";
      }

      return "2px";
    }};
    ${(props) => {
      switch (props.buttonType) {
        case ButtonType.text:
          return `background-color: ${colors.primary.white10}`;

        case ButtonType.primary:
          return `background-color: ${colors.primary.cyan}`;

        default:
          return "";
      }
    }}
  }

  &:active:enabled {
    background-color: ${(props) =>
      props.buttonType === ButtonType.primary
        ? colors.primary.cyan50
        : colors.primary.white16};
  }

  &:hover:enabled ${StyledText} {
    ${(props) => {
      if (
        props.buttonType === ButtonType.secondary &&
        props.buttonSize === ButtonSize.m
      ) {
        return "font-weight: 400";
      }

      return "";
    }}
  }

  &:disabled {
    border-color: ${(props) =>
      props.buttonType === ButtonType.secondary
        ? colors.primary.darkGrey
        : "transparent"};
    background-color: ${(props) =>
      props.buttonType === ButtonType.primary
        ? colors.primary.darkGrey
        : colors.primary.black};
  }

  &:disabled ${StyledText} {
    color: ${(props) => {
      switch (props.buttonType) {
        case ButtonType.primary:
          return colors.primary.black;

        case ButtonType.secondary:
          return colors.primary.lightGrey;

        case ButtonType.text:
          return colors.primary.cyan50;

        default:
          return colors.primary.black;
      }
    }};
    
  &:focus {
    box-shadow: 0px 0px 2px red;
  }
`;
