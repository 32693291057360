import React, {useCallback, useEffect, useState} from "react";
import {MAX_TAGS_COUNT} from "config/constants";
import {InputTagProps} from "./types";

export function useInputTag({label, name, onBlur, onChange}: InputTagProps) {
  const [tags, setTags] = useState<Array<string>>([]);
  const [isActive, setIsActive] = useState(false);
  const [pastedValue, setPastedValue] = useState(false);
  const [state, setState] = useState("");

  useEffect(() => {
    onChange(tags);
  }, [onChange, tags]);

  useEffect(() => {
    if (state && pastedValue) {
      const newTags = state
        .split(" ")
        .flatMap((value) => value.split("#"))
        .flatMap((value) => value.split(","))
        .flatMap((value) => value.trim())
        .filter((value) => value && !tags.includes(value));

      setTags(tags.concat(newTags));
      setPastedValue(false);
      setState("");
    }
  }, [state, pastedValue, tags]);

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLDivElement>): void => {
      setIsActive(false);

      if (onBlur) {
        onBlur(e);
      }
    },
    [onBlur]
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setState(e.target.value);
  };

  const handleFocus = (): void => {
    setIsActive(true);
  };

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>): void => {
      let {value} = e.currentTarget;
      value = value.startsWith("#") ? value.trim().slice(1) : value.trim();

      if (e.key !== " " && e.key !== "Enter") {
        return;
      }

      e.preventDefault();

      if (tags.includes(value)) {
        setState("");
        return;
      }
      // do not allow adding an empty tag (spacebar press without existing chars)
      if (value.length < 1) {
        return;
      }

      const nextTags = [...tags, value];

      setTags(nextTags);
      setState("");
    },
    [tags]
  );

  const handleOnPaste = () => {
    setPastedValue(true);
  };

  const handleRemoveTag = useCallback(
    (tag: string): void => {
      const newTags = tags.filter((e) => e !== tag);
      setTags(newTags);
    },
    [tags]
  );

  const isValuePresent = isActive || state.length > 0;
  const isInputFieldActive = isValuePresent && !!label;
  const isInputDisabled = tags.length >= MAX_TAGS_COUNT;

  return {
    handleBlur,
    handleChange,
    handleFocus,
    handleKeyDown,
    handleOnPaste,
    handleRemoveTag,
    isInputDisabled,
    isInputFieldActive,
    isValuePresent,
    name,
    state,
    tags
  };
}
