import React from "react";
import TextInputFloatingLabel from "components/Atoms/TextInputFloatingLabel";
import {TextTypes} from "components/Atoms/Text/types";

import {Side} from "components/TopNavigation/types";
import {TextInputProps} from "./types";
import {
  Counter,
  HelperText,
  IconContainer,
  Input,
  InputAndLabelContainer,
  InputContainer,
  RightSideContainer,
  LeftSideContainer,
  RootContainer
} from "./styles";
import {useTextInput} from "./hooks";

const TextInput: React.FC<TextInputProps> = (props: TextInputProps) => {
  const {
    borderType,
    helperText,
    hideMaxCharCount,
    id,
    isDisabled,
    isInvalid,
    isSucceeded,
    label,
    maxCharCount,
    placeholder,
    renderIcon,
    className
  } = props;
  const {
    charCount,
    handleOnBlur,
    handleOnChange,
    handleOnFocus,
    inputRef,
    iconColor,
    isFloating,
    isFocused,
    rootContainerOnMouseDown,
    rootContainerOnClick,
    showRightSidePanel,
    textStyle,
    value
  } = useTextInput(props);

  return (
    <RootContainer
      onMouseDown={rootContainerOnMouseDown}
      onClick={rootContainerOnClick}
      className={className}
    >
      <InputContainer
        isDisabled={isDisabled}
        isFocused={isFocused}
        isInvalid={isInvalid}
        isSucceeded={isSucceeded}
        borderType={borderType}
        label={label}
        showRightSidePanel={showRightSidePanel}
      >
        <InputAndLabelContainer label={label}>
          {label && (
            <TextInputFloatingLabel
              isDisabled={isDisabled}
              isFloating={isFloating}
              label={label}
            />
          )}
          {renderIcon && (
            <LeftSideContainer>
              <IconContainer>{renderIcon(iconColor, Side.left)}</IconContainer>
            </LeftSideContainer>
          )}
          <Input
            {...textStyle}
            autoComplete="off"
            id={id}
            ref={inputRef}
            disabled={isDisabled}
            isDisabled={isDisabled}
            label={label}
            maxLength={maxCharCount}
            onBlur={handleOnBlur}
            onChange={handleOnChange}
            onFocus={handleOnFocus}
            placeholder={placeholder}
            value={value}
          />
        </InputAndLabelContainer>

        {showRightSidePanel && (
          <RightSideContainer>
            {renderIcon && (
              <IconContainer>{renderIcon(iconColor)}</IconContainer>
            )}
            {!hideMaxCharCount && maxCharCount && (
              <Counter
                count={charCount}
                isDisabled={isDisabled}
                maxCount={maxCharCount}
              />
            )}
          </RightSideContainer>
        )}
      </InputContainer>

      {helperText && (
        <HelperText
          isDisabled={isDisabled}
          isInvalid={isInvalid}
          isSucceeded={isSucceeded}
          textType={TextTypes.Other.ButtonMLight}
        >
          {helperText}
        </HelperText>
      )}
    </RootContainer>
  );
};

export default React.memo(TextInput);
