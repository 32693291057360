import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import {useText} from "components/Atoms/Text/hooks";
import {TextTypes} from "components/Atoms/Text/types";
import {colors} from "theme/colors";

import {TextInputProps} from "./types";

export function useTextInput({
  hideMaxCharCount,
  initialValue,
  isDisabled,
  isInvalid,
  isSucceeded,
  maxCharCount,
  onBlur,
  onChange,
  onFocus,
  renderIcon
}: TextInputProps) {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [value, setValue] = useState<string>(initialValue || "");
  const [charCount, setCharCount] = useState<number>(initialValue?.length || 0);

  const textStyle = useText({textType: TextTypes.Subtitle.LStrong});
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (initialValue !== undefined) {
      setValue(initialValue);
    }
  }, [initialValue]);

  const handleOnBlur = useCallback(() => {
    setIsFocused(false);
    if (onBlur) {
      onBlur();
    }
  }, [onBlur]);

  const handleOnFocus = useCallback(() => {
    setIsFocused(true);
    if (onFocus) {
      onFocus();
    }
  }, [onFocus]);

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
      setCharCount(e.target.value.length);
      if (onChange) {
        onChange(e.target.value);
      }
    },
    [onChange]
  );

  const isFloating = isFocused || value.length > 0;
  const showRightSidePanel =
    (maxCharCount && !hideMaxCharCount) || renderIcon !== undefined;

  const iconColor = useMemo(() => {
    if (isDisabled) {
      return colors.primary.mediumGrey;
    }

    if (isInvalid) {
      return colors.secondary.error;
    }

    if (isSucceeded) {
      return colors.secondary.success;
    }

    if (isFocused) {
      return colors.primary.cyan;
    }

    return colors.primary.lightGrey;
  }, [isDisabled, isFocused, isInvalid, isSucceeded]);

  const rootContainerOnClick = useCallback(() => {
    inputRef?.current?.focus();
    setIsFocused(true);
    if (onFocus) {
      onFocus();
    }
  }, [onFocus]);

  const rootContainerOnMouseDown = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
    },
    []
  );

  return {
    charCount,
    handleOnBlur,
    handleOnChange,
    handleOnFocus,
    iconColor,
    inputRef,
    isFloating,
    isFocused,
    rootContainerOnClick,
    rootContainerOnMouseDown,
    showRightSidePanel,
    textStyle,
    value
  };
}
