import {useMemo} from "react";
import {
  FontStyle,
  PossibleTextTypes,
  TextTypes,
  TextWights,
  UseTextProps
} from "./types";

function getTextWeight(textWeight?: TextWights): number {
  switch (textWeight) {
    case TextWights.Medium:
      return 500;

    case TextWights.Regular:
      return 400;

    case TextWights.Light:
      return 300;

    default:
      return 400;
  }
}

export function useText({textType, textWeight}: UseTextProps): FontStyle {
  return useMemo(() => {
    // We have only this font family so far, but the things could change
    const fontFamily =
      "proximanova, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue";

    const weight = getTextWeight(textWeight);

    switch (textType) {
      case TextTypes.Titles.H1:
        return {
          fontFamily,
          fontSize: "48px",
          lineHeight: 57,
          fontWeight: getTextWeight(TextWights.Medium)
        };
      case TextTypes.Titles.H1Light:
        return {
          fontFamily,
          fontSize: "36px",
          lineHeight: 24,
          fontWeight: getTextWeight(TextWights.Medium)
        };
      case TextTypes.Titles.H2:
        return {
          fontFamily,
          fontSize: "32px",
          lineHeight: 38,
          fontWeight: getTextWeight(TextWights.Medium)
        };

      case TextTypes.Titles.H2Light:
        return {
          fontFamily,
          fontSize: "32px",
          lineHeight: 38,
          fontWeight: getTextWeight(TextWights.Light)
        };

      case TextTypes.Titles.H3:
        return {
          fontFamily,
          fontSize: "27px",
          lineHeight: 32,
          fontWeight: getTextWeight(TextWights.Medium)
        };

      case TextTypes.Titles.H4:
        return {
          fontFamily,
          fontSize: "21px",
          lineHeight: 26,
          fontWeight: getTextWeight(TextWights.Medium)
        };

      case TextTypes.Titles.H4Light:
        return {
          fontFamily,
          fontSize: "21px",
          lineHeight: 26,
          fontWeight: getTextWeight(TextWights.Light)
        };

      case TextTypes.Titles.H5:
        return {
          fontFamily,
          fontSize: "12px",
          lineHeight: 22,
          fontWeight: getTextWeight(TextWights.Medium)
        };

      case TextTypes.Titles.H5Light:
        return {
          fontFamily,
          fontSize: "12px",
          lineHeight: 22,
          fontWeight: getTextWeight(TextWights.Light)
        };

      case TextTypes.Titles.H6:
        return {
          fontFamily,
          fontSize: "15px",
          lineHeight: 20,
          fontWeight: getTextWeight(TextWights.Medium)
        };

      case TextTypes.Titles.H7:
        return {
          fontFamily,
          fontSize: "15px",
          lineHeight: 20,
          fontWeight: getTextWeight(TextWights.Light)
        };

      case TextTypes.Subtitle.LStrong:
        return {
          fontFamily,
          fontSize: "13px",
          lineHeight: 18,
          fontWeight: getTextWeight(TextWights.Medium)
        };

      case TextTypes.Subtitle.L:
        return {
          fontFamily,
          fontSize: "13px",
          lineHeight: 18,
          fontWeight: getTextWeight(TextWights.Light)
        };

      case TextTypes.Subtitle.MStrong:
        return {
          fontFamily,
          fontSize: "11px",
          lineHeight: 13,
          fontWeight: getTextWeight(TextWights.Medium)
        };

      case TextTypes.Subtitle.M:
        return {
          fontFamily,
          fontSize: "11px",
          lineHeight: 13,
          fontWeight: getTextWeight(TextWights.Light)
        };

      case TextTypes.Paragraph.L:
        return {
          fontFamily,
          fontSize: "16px",
          lineHeight: 25,
          fontWeight: getTextWeight(TextWights.Regular)
        };

      case TextTypes.Paragraph.LLight:
        return {
          fontFamily,
          fontSize: "15px",
          lineHeight: 20,
          fontWeight: getTextWeight(TextWights.Light)
        };

      case TextTypes.Paragraph.M:
        return {
          fontFamily,
          fontSize: "13px",
          lineHeight: 22,
          fontWeight: getTextWeight(TextWights.Regular)
        };

      case TextTypes.Paragraph.MLight:
        return {
          fontFamily,
          fontSize: "13px",
          lineHeight: 18,
          fontWeight: getTextWeight(TextWights.Light)
        };

      case TextTypes.Paragraph.S:
        return {
          fontFamily,
          fontSize: "10px",
          lineHeight: 10,
          fontWeight: getTextWeight(TextWights.Regular)
        };

      case TextTypes.Other.ButtonL:
        return {
          fontFamily,
          fontSize: "17px",
          lineHeight: 24,
          fontWeight: getTextWeight(TextWights.Medium)
        };

      case TextTypes.Other.ButtonM:
        return {
          fontFamily,
          fontSize: "13px",
          lineHeight: 18,
          fontWeight: getTextWeight(TextWights.Medium)
        };

      case TextTypes.Other.ButtonMLight:
        return {
          fontFamily,
          fontSize: "13px",
          lineHeight: 18,
          fontWeight: getTextWeight(TextWights.Light)
        };

      case TextTypes.Other.ButtonS:
        return {
          fontFamily,
          fontSize: "12px",
          lineHeight: 16,
          fontWeight: getTextWeight(TextWights.Medium)
        };

      case TextTypes.Other.LinkLabelL:
        return {
          fontFamily,
          fontSize: "15px",
          lineHeight: 20,
          fontWeight: getTextWeight(TextWights.Medium)
        };
      case TextTypes.Other.LinkLabelMLight:
        return {
          fontFamily,
          fontSize: "15px",
          lineHeight: 24,
          fontWeight: getTextWeight(TextWights.Medium)
        };
      case TextTypes.Other.LinkLabelLLight:
        return {
          fontFamily,
          fontSize: "15px",
          lineHeight: 20,
          fontWeight: getTextWeight(TextWights.Light)
        };

      case TextTypes.Other.LinkLabelM:
        return {
          fontFamily,
          fontSize: "13px",
          lineHeight: 18,
          fontWeight: getTextWeight(TextWights.Regular)
        };

      // older
      case TextTypes.Headings.H1:
        return {
          fontFamily,
          fontSize: "49px",
          fontWeight: weight,
          lineHeight: 59
        };

      case TextTypes.Headings.H2:
        return {
          fontFamily,
          fontSize: "39px",
          fontWeight: weight,
          lineHeight: 48
        };

      case TextTypes.Headings.H3:
        return {
          fontFamily,
          fontSize: "31px",
          fontWeight: weight,
          lineHeight: 38
        };

      case TextTypes.Headings.H4:
        return {
          fontFamily,
          fontSize: "25px",
          fontWeight: weight,
          lineHeight: 31
        };

      case TextTypes.Headings.H5:
        return {
          fontFamily,
          fontSize: "20px",
          fontWeight: weight,
          lineHeight: 24
        };

      case TextTypes.SubHeadings.SH1:
        return {
          fontFamily,
          fontSize: "21px",
          fontWeight: 400,
          lineHeight: 26
        };

      case TextTypes.SubHeadings.SH2:
        return {
          fontFamily,
          fontSize: "20px",
          fontWeight: 400,
          lineHeight: 20
        };

      case TextTypes.SubHeadings.SH3:
        return {
          fontFamily,
          fontSize: "18px",
          fontWeight: 400,
          lineHeight: 22
        };

      case TextTypes.Body:
        return {
          fontFamily,
          fontSize: "15px",
          fontWeight: 300,
          lineHeight: 18
        };

      case TextTypes.Bodies.B1:
        return {
          fontFamily,
          fontSize: "16px",
          fontWeight: weight,
          lineHeight: 25
        };

      case TextTypes.Bodies.B2:
        return {
          fontFamily,
          fontSize: "13px",
          fontWeight: weight,
          lineHeight: 23
        };
      case TextTypes.Bodies.B3:
        return {
          fontFamily,
          fontSize: "10px",
          fontWeight: weight,
          lineHeight: 12
        };

      // Legacy

      case PossibleTextTypes.ButtonText:
        return {
          fontFamily,
          fontSize: "16px",
          fontWeight: 700,
          lineHeight: 22
        };

      case PossibleTextTypes.CardDescription:
        return {
          fontFamily,
          fontSize: "16px",
          fontWeight: 400,
          lineHeight: 22
        };

      case PossibleTextTypes.CardTitle:
        return {
          fontFamily,
          fontSize: "20px",
          fontWeight: 700,
          lineHeight: 27
        };

      case PossibleTextTypes.MenuItem:
        return {
          fontFamily,
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: 18
        };

      case PossibleTextTypes.SmallDescription:
        return {
          fontFamily,
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: 18
        };

      case PossibleTextTypes.PageTitle:
        return {
          fontFamily,
          fontSize: "42px",
          fontWeight: 700,
          lineHeight: 57
        };

      case PossibleTextTypes.H1:
        return {
          fontFamily,
          fontSize: "64px",
          fontWeight: 700,
          lineHeight: 87
        };

      case PossibleTextTypes.H2:
        return {
          fontFamily,
          fontSize: "56px",
          fontWeight: 700,
          lineHeight: 76
        };

      case PossibleTextTypes.H4:
        return {
          fontFamily,
          fontSize: "48px",
          fontWeight: 700,
          lineHeight: 66
        };

      case PossibleTextTypes.H5:
        return {
          fontFamily,
          fontSize: "28px",
          fontWeight: 400,
          lineHeight: 32
        };

      // Legacy

      default:
        // same as VideoName
        return {
          fontFamily,
          fontSize: "15px",
          fontWeight: 400,
          lineHeight: 20
        };
    }
  }, [textType, textWeight]);
}
