export interface FontStyle {
  fontFamily: string;
  fontSize: string;
  fontWeight: number;
  lineHeight: number;
}

export enum PossibleTextTypes {
  TitleH1 = "TitleH1",
  H1Light = "H1Light",
  TitleH2 = "TitleH2",
  TitleH2Light = "TitleH2Light",
  TitleH3 = "TitleH3",
  TitleH4 = "TitleH4",
  TitleH4Light = "TitleH4Light",
  TitleH5 = "TitleH5",
  TitleH5Light = "TitleH5Light",
  TitleH6 = "TitleH6",
  TitleH7 = "TitleH7",

  SubtitleLStrong = "SubtitleLStrong",
  SubtitleMStrong = "SubtitleMStrong",
  SubtitleL = "SubtitleL",
  SubtitleM = "SubtitleM",

  ParagraphL = "ParagraphL",
  ParagraphLLight = "ParagraphLLight",
  ParagraphM = "ParagraphM",
  ParagraphMLight = "ParagraphMLight",
  ParagraphS = "ParagraphLS",

  ButtonL = "ButtonL",
  ButtonM = "ButtonM",
  ButtonMLight = "ButtonMLight",
  ButtonS = "ButtonS",

  LinkLabelL = "LinkLabelL",
  LinkLabelLLight = "LinkLabelLLight",
  LinkLabelM = "LinkLabelM",
  LinkLabelMLight = "LinkLabelMLight",

  Heading1 = "Heading1",
  Heading2 = "Heading2",
  Heading3 = "Heading3",
  Heading4 = "Heading4",
  Heading5 = "Heading5",

  SubHeading1 = "SubHeading1",
  SubHeading2 = "SubHeading2",
  SubHeading3 = "SubHeading3",

  Body = "Body",
  Body1 = "Body1",
  Body2 = "Body2",
  Body3 = "Body3",

  // Legacy types for Marketplace and MintNFTs pages

  ButtonText = "ButtonText",
  CardDescription = "CardDescription",
  CardTitle = "CardTitle",
  MenuItem = "MenuItem",
  PageTitle = "PageTitle",
  SmallDescription = "SmallDescription",
  H1 = "H1",
  H2 = "H2",
  H3 = "H3",
  H4 = "H4",
  H5 = "H5"
}

const titles = {
  H1: PossibleTextTypes.TitleH1,
  H1Light: PossibleTextTypes.TitleH1,
  H2: PossibleTextTypes.TitleH2,
  H2Light: PossibleTextTypes.TitleH2Light,
  H3: PossibleTextTypes.TitleH3,
  H4: PossibleTextTypes.TitleH4,
  H4Light: PossibleTextTypes.TitleH4Light,
  H5: PossibleTextTypes.TitleH5,
  H5Light: PossibleTextTypes.TitleH5Light,
  H6: PossibleTextTypes.TitleH6,
  H7: PossibleTextTypes.TitleH7
};

const subtitles = {
  LStrong: PossibleTextTypes.SubtitleLStrong,
  L: PossibleTextTypes.SubtitleL,
  MStrong: PossibleTextTypes.SubtitleMStrong,
  M: PossibleTextTypes.SubtitleM
};

const paragraph = {
  L: PossibleTextTypes.ParagraphL,
  LLight: PossibleTextTypes.ParagraphLLight,
  M: PossibleTextTypes.ParagraphM,
  MLight: PossibleTextTypes.ParagraphMLight,
  S: PossibleTextTypes.ParagraphS
};

const otherTypes = {
  ButtonL: PossibleTextTypes.ButtonL,
  ButtonM: PossibleTextTypes.ButtonM,
  ButtonMLight: PossibleTextTypes.ButtonMLight,
  ButtonS: PossibleTextTypes.ButtonS,

  LinkLabelL: PossibleTextTypes.LinkLabelL,
  LinkLabelLLight: PossibleTextTypes.LinkLabelLLight,
  LinkLabelM: PossibleTextTypes.LinkLabelM,
  LinkLabelMLight: PossibleTextTypes.LinkLabelMLight
};

const headings = {
  H1: PossibleTextTypes.Heading1,
  H2: PossibleTextTypes.Heading2,
  H3: PossibleTextTypes.Heading3,
  H4: PossibleTextTypes.Heading4,
  H5: PossibleTextTypes.Heading5
};

const subHeadings = {
  SH1: PossibleTextTypes.SubHeading1,
  SH2: PossibleTextTypes.SubHeading2,
  SH3: PossibleTextTypes.SubHeading3
};

const bodies = {
  B1: PossibleTextTypes.Body1,
  B2: PossibleTextTypes.Body2,
  B3: PossibleTextTypes.Body3
};

export const TextTypes = {
  // new types
  Titles: titles,
  Subtitle: subtitles,
  Paragraph: paragraph,
  Other: otherTypes,

  // obsolete types to replace
  Headings: headings,
  SubHeadings: subHeadings,
  Bodies: bodies,
  Body: PossibleTextTypes.Body
};

export enum TextWights {
  Regular,
  Medium,
  Light
}

export interface UseTextProps {
  textType: PossibleTextTypes;
  textWeight?: TextWights;
}

export interface TextProps extends UseTextProps {
  className?: string;
}
