import React from "react";
import {strings} from "config/strings";
import {MAX_TAGS_COUNT} from "config/constants";
import Tag from "components/Atoms/Tag";

import {InputTagProps} from "./types";
import {useInputTag} from "./hooks";
import {
  Container,
  Counter,
  InputContainer,
  InputField,
  Label,
  TagsList
} from "./styles";

const InputTag: React.FC<InputTagProps> = (props: InputTagProps) => {
  const {invalid, label} = props;
  const {
    handleBlur,
    handleChange,
    handleFocus,
    handleKeyDown,
    handleOnPaste,
    handleRemoveTag,
    isInputDisabled,
    isInputFieldActive,
    isValuePresent,
    state,
    tags
  } = useInputTag(props);

  return (
    <Container>
      {tags.length > 0 && (
        <TagsList>
          {tags.map((tag: string) => (
            <Tag key={tag} onDelete={handleRemoveTag} tag={tag} />
          ))}
        </TagsList>
      )}
      <InputContainer
        invalid={invalid}
        onBlur={handleBlur}
        onFocus={handleFocus}
      >
        <InputField
          active={isInputFieldActive}
          disabled={isInputDisabled}
          invalid={invalid}
          placeholder={strings.pages.upload.enterTag}
          type="text"
          value={state}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onPaste={handleOnPaste}
        />
        {label && <Label active={isValuePresent}>{label}</Label>}
        <Counter>
          {tags.length}/{MAX_TAGS_COUNT}
        </Counter>
      </InputContainer>
    </Container>
  );
};

export default React.memo(InputTag);
