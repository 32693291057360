import React from "react";
import Text from "components/Atoms/Text";
import {PossibleTextTypes} from "components/Atoms/Text/types";
import {StyledLink, LinkContainer} from "./styles";
import {TopNavigationMenuItemProps} from "./types";

const TopNavigationMenuItem: React.FC<TopNavigationMenuItemProps> = (
  props: TopNavigationMenuItemProps
) => {
  const {hasUnderscore, isSelected, route, title, icon, ...otherProps} = props;
  return (
    <StyledLink
      {...otherProps}
      hasUnderscore={hasUnderscore}
      isSelected={isSelected}
      to={route}
    >
      <LinkContainer hasIcon={!!icon}>
        {icon && <img src={icon} alt="upload icon" />}
        <Text textType={PossibleTextTypes.MenuItem}>{title}</Text>
      </LinkContainer>
    </StyledLink>
  );
};

export default React.memo(TopNavigationMenuItem);
