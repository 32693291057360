import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: block;
  width: 100%;
  min-height: 45px;
  height: auto;
  margin-bottom: 10px;
  padding-left: 8px;
`;

export const AvatarContainer = styled.div`
  position: absolute;
  top: 3px;
`;

export const TextContainer = styled.div`
  display: inline-block;
  width: 83%;
  margin-left: 38px;
`;

export const Header = styled.div`
  font-size: 11px;
  line-height: 13px;
  color: #ffffff;
  margin-bottom: 3px;
`;

export const Body = styled.div`
  font-size: 13px;
  line-height: 18px;
  color: #ffffff;
  white-space: pre;
`;

export const Time = styled.div`
  position: absolute;
  top: 4px;
  right: 15px;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  text-align: right;
  color: rgba(255, 255, 255, 0.72);
`;
