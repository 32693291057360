import styled from "styled-components";
import {ReactComponent as SearchIconSvg} from "icons/universal/search.svg";
import {ReactComponent as ClearIconSvg} from "icons/universal/x.svg";
import TextInput from "components/Atoms/TextInput";
import {InputContainer} from "components/Atoms/TextInput/styles";
import {colors} from "theme/colors";

export const ClearButton = styled.button`
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: ${colors.primary.lightGrey};
  background-color: transparent;
  border: none;
  outline: none;
`;

export const ClearIcon = styled(ClearIconSvg)`
  width: 9px;
  height: 9px;
  margin-left: 8px;
  cursor: pointer;
`;

export const SearchIcon = styled(SearchIconSvg)`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  pointer-events: none;
`;

export const TextInputStyled = styled(TextInput)`
  ${InputContainer} {
    border: 2px solid #ffffff;
    background-color: transparent;
  }

  ${InputContainer}:focus-within {
    background-color: ${colors.primary.black};
  }

  ${SearchIcon} {
    stroke: ${colors.primary.white};
  }
  ${ClearIcon} {
    stroke: ${colors.primary.lightGrey};
  }
  input {
    &::placeholder {
      color: #ffffff;
    }
    &:focus::placeholder {
      color: #ffffff;
    }
  }
`;
