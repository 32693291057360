import React from "react";
import styled from "styled-components";
import {
  formStyles,
  errorRed,
  successGreen,
  accentColor
} from "config/styleVars";
import ErrorIcon from "icons/24/alert-triangle.svg";
import GreenCheckIcon from "icons/24/check-green.svg";
import WhiteCheckIcon from "icons/24/check-white.svg";

type WithValidProps = {
  invalid?: boolean;
};

export const StyledInput = styled.input<WithValidProps>`
  width: 100%;
  border: 1px solid
    ${(props) =>
      props.invalid ? formStyles.errorBorderColor : formStyles.borderColor};
  border-radius: ${formStyles.borderRadius};
  background: ${formStyles.backgroundColor};
  appearance: none;
  padding: ${formStyles.inputPadding};
  color: ${formStyles.textColor};
  box-sizing: border-box;
  margin-bottom: ${(props) => (props.invalid ? 0 : formStyles.marginBottom)};
  outline: none;
  font-weight: 200;
  font-size: 15px;

  &:active,
  &:focus {
    border-color: ${(props) =>
      props.invalid
        ? formStyles.errorBorderColor
        : formStyles.activeBorderColor};
  }

  .invalid {
    border-color: ${formStyles.errorBorderColor};
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export function TextInput(props: any) {
  return <StyledInput {...props} type="text" />;
}

export const TextArea = styled.textarea<WithValidProps>`
  width: 100%;
  border: 1px solid
    ${(props) =>
      props.invalid ? formStyles.errorBorderColor : formStyles.borderColor};
  border-radius: ${formStyles.borderRadius};
  background: ${formStyles.backgroundColor};
  appearance: none;
  padding: ${formStyles.inputPadding};
  color: ${formStyles.textColor};
  box-sizing: border-box;
  margin-bottom: ${(props) => (props.invalid ? 0 : formStyles.marginBottom)};
  outline: none;
  font-weight: 200;
  font-size: 15px;

  &:active,
  &:focus {
    border-color: ${formStyles.activeBorderColor};
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const Label = styled.label`
  color: ${formStyles.textColor}
  text-transform: uppercase;
  display: block;
  margin-bottom: 3px;
`;

export const Checkbox = styled(StyledInput)`
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-bottom: 0;

  & + label {
    line-height: 24px;
    display: inline-block;
    vertical-align: top;
    margin-left: 12px;
    position: relative;
  }
  &:checked {
    background-color: rgba(255, 255, 255, 0.1);
  }
  &:checked + label::before {
    content: "";
    background-image: url(${WhiteCheckIcon});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    position: absolute;
    top: 0;
    left: -36px;
    width: 24px;
    height: 24px;
  }
`;

export const Button = styled.button`
  appearance: none;
  border: 1px solid ${formStyles.borderColor};
  border-radius: ${formStyles.borderRadius};
  background: transparent;
  color: ${formStyles.textColor};
  padding: 9px 17px;
  margin-right: 1px;

  &:hover {
    border: 1px solid ${formStyles.buttonHoverBorderColor};
    box-shadow: 0 0 0 1px ${formStyles.buttonHoverBorderColor};
  }

  &:active {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const TextButton = styled(Button)`
  background: transparent;
  border: 1px solid transparent;
  color: ${formStyles.activeBorderColor};

  &:hover {
    background-color: #1a1a1a;
    border: 1px solid #1a1a1a;
    box-shadow: none;
  }

  &:active {
    opacity: 1;
    background-color: #262626;
    border: 1px solid #262626;
  }
`;

export const InlineTextButton = styled.button`
  appearance: none;
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
  color: ${accentColor};

  &:hover {
    text-decoration: underline;
  }
`;

export const FieldValidationMessage = styled.div`
  font-size: 0.8em;
  color: ${errorRed};
  margin-bottom: ${formStyles.marginBottom};
`;

export const FieldCount = styled.div`
  font-size: 0.8em;
  opacity: 0.5;
  position: absolute;
  right: ${formStyles.inputPadding};
  bottom: ${formStyles.inputPadding};
`;

const FormResponseMessage = styled.div`
  font-size: 13px;
  padding: 11px 11px 11px 48px;
  line-height: 1.3;
  border-radius: ${formStyles.borderRadius};
  margin-bottom: ${formStyles.marginBottom};
  position: relative;

  img {
    position: absolute;
    top: 50%;
    left: 11px;
    margin-top: -13px;
  }
`;

export enum ResponseMessageTypes {
  Error = "error",
  Success = "success"
}

type ResponseMessageProps = {
  type: ResponseMessageTypes;
  message: string;
};

type ResponseColorStyles = {
  color?: string;
  backgroundColor?: string;
};

export const ResponseMessage: React.FC<ResponseMessageProps> = (
  props: ResponseMessageProps
) => {
  const {message, type} = props;

  function getColorStyles(
    messageType: ResponseMessageTypes
  ): ResponseColorStyles {
    switch (messageType) {
      case ResponseMessageTypes.Error:
        return {
          color: errorRed,
          backgroundColor: "rgba(231, 81, 75, 0.2)"
        };
      case ResponseMessageTypes.Success:
        return {
          color: successGreen,
          backgroundColor: "rgba(76, 175, 80, 0.12)"
        };
    }
    return {};
  }

  return (
    <FormResponseMessage style={getColorStyles(type)}>
      {type === ResponseMessageTypes.Error && (
        <img alt="Error Icon" src={ErrorIcon} />
      )}
      {type === ResponseMessageTypes.Success && (
        <img alt="Check Icon" src={GreenCheckIcon} />
      )}
      {message}
    </FormResponseMessage>
  );
};
