import React, {ReactChild, ReactChildren} from "react";
import ReactModal from "react-modal";
import {strings} from "config/strings";

import {
  CloseButton,
  Heading,
  Subheading,
  Content,
  Footer,
  FooterButton
} from "./styles";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(0, 0, 0, 0.72)",
    border: "1px solid rgba(75, 73, 77, 0.4)",
    backdropFilter: "blur(8px)",
    borderRadius: "4px",
    width: "480px",
    overflow: "visible",
    padding: "0"
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.64)",
    zindex: 99999
  }
};

type ModalProps = {
  isOpen: boolean;
  closeModalFn: () => void;
  isDialog: boolean;
  heading?: string;
  subheading?: string;
  children?: ReactChild | ReactChildren;
  saveFn?: () => void;
  saveBtnLabel?: string;
  message?: string;
  isMessage?: boolean;
  cancelBtnLabel?: string;
};

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  saveBtnLabel,
  cancelBtnLabel,
  closeModalFn,
  saveFn,
  heading,
  isMessage,
  subheading,
  children,
  isDialog,
  message
}: ModalProps) => {
  const handleCloseModal = () => {
    closeModalFn();
  };

  const handleSave = () => {
    if (saveFn) {
      saveFn();
    }
  };

  const getSaveBtnLabel = () => {
    return saveBtnLabel || strings.generic.save;
  };

  const getCancelBtnLabel = () => {
    return cancelBtnLabel || strings.generic.cancel;
  };

  return (
    <>
      <ReactModal
        contentLabel="Upload profile image"
        isOpen={isOpen}
        preventScroll={true}
        shouldCloseOnOverlayClick={true}
        style={customStyles}
        onRequestClose={handleCloseModal}
      >
        <CloseButton onClick={handleCloseModal}>
          {strings.generic.close}
        </CloseButton>

        {!isMessage && (
          <>
            {heading && <Heading>{heading}</Heading>}

            {subheading && <Subheading>{subheading}</Subheading>}

            <Content>{children}</Content>
          </>
        )}

        {isMessage && (
          <>
            {heading && <Heading>{heading}</Heading>}
            {/* eslint-disable-next-line react-extra/no-inline-styles -- MAR-844 */}
            <Subheading style={{margin: "10% 0%"}}>{message}</Subheading>
          </>
        )}

        {isDialog && (
          <Footer>
            {!isMessage && (
              <>
                <FooterButton onClick={handleCloseModal}>
                  {getCancelBtnLabel()}
                </FooterButton>
                <FooterButton accent={true} onClick={handleSave}>
                  {getSaveBtnLabel()}
                </FooterButton>
              </>
            )}

            {isMessage && (
              <>
                <FooterButton onClick={handleCloseModal}>
                  {strings.generic.ok}
                </FooterButton>
              </>
            )}
          </Footer>
        )}
      </ReactModal>
    </>
  );
};
