import React, {useState, useEffect} from "react";
import {strings} from "config/strings";
import {FeedVideo} from "apollo/types";
import {VideoJsPlayer} from "video.js";
import videojs from "@mux/videojs-kit";
import "@mux/videojs-kit/dist/index.css";
import {Link, useHistory} from "react-router-dom";
import {getFeedPlayerUrl, getProfileUrl} from "config/routes";

import {audioStateVar, stopVideoStateVar} from "apollo/reactive";
import {Spinner} from "components/Spinner";
import Avatar from "components/Avatar";
import PlayIcon from "icons/play.svg";
import MutedIcon from "icons/24/volume-x.svg";
import NotMutedIcon from "icons/24/volume-2.svg";
import {useReactiveVar} from "@apollo/client";
import {useCountFormat} from "hooks/useCountFormat";
import {formatTimeDistance} from "utils/FormatUtils";
import VideoErrorContainer from "./VideoErrorContainer/VideoErrorContainer";
import {
  FeaturedWrapper,
  Meta,
  MetaButtons,
  MetaText,
  MuteButton,
  FeaturedUsername,
  VideoTitle,
  WatchNow,
  WatchNowIcon,
  MetaViewsNumber,
  MetaViews,
  MetaAdditional,
  MetaNumberDistance,
  MetaVignette
} from "./PlayerStyles";

type Props = {
  videos?: FeedVideo[];
  videoIndex: number;
};

const FeaturedVideo: React.FC<Props> = ({videos, videoIndex}: Props) => {
  const [player, setPlayer] = useState<VideoJsPlayer | null>(null);
  const [videoRef, setVideoRef] = useState<any | null>(null);
  const [muted, setMuted] = useState(false);
  const [video, setVideo] = useState<FeedVideo>();
  const [onError, setOnError] = useState(false);
  const audioState = useReactiveVar(audioStateVar);
  const stopVideoState = useReactiveVar(stopVideoStateVar);
  const history = useHistory();

  useEffect(() => {
    setVideo(videos?.[videoIndex]);
  }, [videoIndex, videos]);

  const handleSpacebarKey = (e: KeyboardEvent) => {
    if (e.code === "Space") {
      e.preventDefault();
      if (player?.paused()) {
        player?.play();
      } else {
        player?.pause();
      }
    }
  };

  const changeMuteState = (state: boolean) => {
    player?.muted(state);
    setMuted(state);
  };

  useEffect(() => {
    window.addEventListener("keydown", handleSpacebarKey);

    return () => {
      window.removeEventListener("keydown", handleSpacebarKey);
    };
  });

  useEffect(() => {
    if (stopVideoState) {
      player?.pause();
    } else {
      player?.play();
    }
  }, [stopVideoState]);

  useEffect(() => {
    if (video && videoRef && !player) {
      setPlayer(
        videojs(videoRef, {
          timelineHoverPreviewsUrl: false,
          plugins: {
            mux: {
              debug: false,
              data: {
                env_key: process.env.REACT_APP_MUX_KEY,
                player_name: "Theater Mode Player",
                player_init_time: Date.now()
              }
            }
          }
        })
      );
    }
    if (videoRef && player && video && !player.src()) {
      player.src({
        src: video.playbackUrl.split("https://stream.mux.com/")[1],
        type: "video/mux"
      });
      player.ready(() => {
        player.volume(audioState.volume);
        changeMuteState(audioState.muted);
        player.play();
      });
    }
  }, [videoRef, player, video]);

  const handleWatchNow = () => {
    if (video) {
      const url = getFeedPlayerUrl(video.externalId, videoRef.currentTime);
      history.push(url);
    }
  };

  const handleMuteToggle = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (player) {
      changeMuteState(!muted);
      audioStateVar({...audioState, muted: !muted});
    }
  };

  const numberOfPlays = useCountFormat(video?.plays);

  return (
    <FeaturedWrapper onClick={handleWatchNow}>
      {video ? (
        <>
          <Meta>
            <Avatar
              avatarURL={video?.avatar}
              size={38}
              username={video?.username}
              uuid={video?.userUuid}
            />
            <MetaText>
              <VideoTitle>{video?.title}</VideoTitle>
              <Link
                to={getProfileUrl(video?.username)}
                onClick={(e) => e.stopPropagation()}
              >
                <FeaturedUsername>{video?.username}</FeaturedUsername>
              </Link>
              <MetaViews>
                <MetaViewsNumber>{numberOfPlays}</MetaViewsNumber>
                {video.plays > 0 && <MetaAdditional> views</MetaAdditional>}
                <MetaNumberDistance>
                  {formatTimeDistance(video.createdOn)}
                </MetaNumberDistance>
                <MetaAdditional> ago</MetaAdditional>
              </MetaViews>
            </MetaText>

            <MetaButtons>
              <WatchNow onClick={handleWatchNow}>
                <WatchNowIcon alt="play" src={PlayIcon} />
                {strings.generic.watchNow}
              </WatchNow>
              <MuteButton onClick={handleMuteToggle}>
                {muted ? (
                  <img alt="muted" src={MutedIcon} />
                ) : (
                  <img alt="not muted" src={NotMutedIcon} />
                )}
              </MuteButton>
            </MetaButtons>
          </Meta>
          <MetaVignette />
          {onError ? (
            <VideoErrorContainer />
          ) : (
            <video
              autoPlay={false}
              className="video-js vjs-fill"
              height="100%"
              id="mux-featured-vid"
              loop={true}
              preload="auto"
              ref={setVideoRef}
              width="100%"
              onClick={handleWatchNow}
              onError={() => setOnError(true)}
            />
          )}
        </>
      ) : (
        <Spinner />
      )}
    </FeaturedWrapper>
  );
};

export default React.memo(FeaturedVideo);
