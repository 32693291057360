import {TextTypes} from "components/Atoms/Text/types";

import {useMemo} from "react";
import {colors} from "theme/colors";
import {ButtonProps, ButtonSize, ButtonType} from "./types";

export function useButton({disabled, size, type}: ButtonProps) {
  const textType = useMemo(() => {
    switch (size) {
      case ButtonSize.l:
        return TextTypes.Other.ButtonL;

      case ButtonSize.m:
        return TextTypes.Other.ButtonM;

      case ButtonSize.s:
        return TextTypes.Other.ButtonS;

      default:
        return TextTypes.Other.ButtonM;
    }
  }, [size]);

  const iconColor = useMemo(() => {
    switch (type) {
      case ButtonType.primary:
        return colors.primary.black;

      case ButtonType.secondary:
        return disabled ? colors.primary.lightGrey : colors.primary.white;

      case ButtonType.text:
        return disabled ? colors.primary.cyan50 : colors.primary.cyan;

      default:
        return colors.primary.white;
    }
  }, [disabled, type]);

  const iconSize = useMemo(() => {
    return size === ButtonSize.s ? 20 : 24;
  }, [size]);

  return {
    iconColor,
    iconSize,
    textType
  };
}
