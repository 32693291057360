import styled from "styled-components";

import {textGray, formStyles} from "config/styleVars";

type InputProps = {
  active?: boolean;
  invalid?: boolean;
};

type LabelProps = {
  active?: boolean;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  &:focus-within label {
    transform: translate(6px, 2px) scale(0.8);
  }
`;

export const InputField = styled.textarea<InputProps>`
  width: 100%;
  border: 1px solid
    ${(props) =>
      props.invalid ? formStyles.errorBorderColor : formStyles.borderColor};
  border-radius: ${formStyles.borderRadius};
  background: ${formStyles.backgroundColor};
  appearance: none;
  padding: ${(props) =>
    props.active ? "21px 14px 7px 14px" : formStyles.inputPadding};
  color: ${formStyles.textColor};
  box-sizing: border-box;
  outline: none;
  font-weight: 200;
  font-size: 15px;
  resize: vertical;

  &:autofill:focus,
  &:active,
  &:focus {
    border-color: ${formStyles.activeBorderColor};
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:autofill:hover,
  &:autofill {
    box-shadow: 0 0 0 30px ${formStyles.backgroundColor} inset;
    -webkit-text-fill-color: ${formStyles.textColor};
    transition: background-color 5000s ease-in-out 0s;
  }
`;

export const Label = styled.label<LabelProps>`
  position: absolute;
  padding: 0 12px;
  font-size: 16px;
  color: ${textGray};
  pointer-events: none;
  transform: ${(props) =>
    props.active
      ? "translate(6px, 2px) scale(0.80)"
      : "translate(0, 12px) scale(1)"};
  transform-origin: top left;
  transition: all 0.2s ease-out;
`;
