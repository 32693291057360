import {FontStyle} from "components/Atoms/Text/types";
import {Side} from "components/TopNavigation/types";

export enum InputBorderType {
  underlined,
  outlined
}

export interface StateProps {
  isDisabled?: boolean;
  isInvalid?: boolean;
  isSucceeded?: boolean;
}

export interface TextInputProps extends StateProps {
  borderType?: InputBorderType;
  helperText?: string;
  hideMaxCharCount?: boolean;
  id?: string;
  initialValue?: string;
  className?: string;
  label?: string;
  maxCharCount?: number;
  onBlur?: () => void;
  onChange?: (text: string) => void;
  onFocus?: () => void;
  placeholder?: string;
  renderIcon?: (color: string, side?: Side) => JSX.Element | null;
}

export interface TextInputContainerProps extends StateProps {
  borderType?: InputBorderType;
  isFocused: boolean;
  backgroundColor?: string;
  label?: string;
  showRightSidePanel: boolean;
  borderColor?: string;
}

export interface InputProps extends FontStyle {
  isDisabled?: boolean;
  label?: string;
}
