import React from "react";

import {RootContainer} from "./styles";
import {useUpload} from "./hooks";

const Upload: React.FC = () => {
  const {renderStep} = useUpload();

  return <RootContainer>{renderStep()}</RootContainer>;
};

export default React.memo(Upload);
