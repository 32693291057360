import {MouseEventHandler, useCallback, useMemo} from "react";
import {strings} from "config/strings";
import {formatCountNumber, formatTimeStamp} from "utils/FormatUtils";
import moment from "moment";
import {getProfileUrl} from "config/routes";

const VISIBILITY_OFFSET = -200;

export function useVideoListItem({
  createdOn,
  duration,
  externalId,
  index,
  onClick,
  openDeleteConfirmation,
  openUnlikeModal,
  plays,
  username
}: {
  createdOn: string;
  duration: string;
  externalId: string;
  index: number;
  onClick: (index: number) => void;
  openDeleteConfirmation?: (externalId: string) => void;
  openUnlikeModal?: (externalId: string) => void;
  plays: number;
  username: string;
  title: string;
}) {
  const viewsText = useMemo(() => {
    if (!plays) {
      return null;
    }

    const formatted = formatCountNumber(plays);
    const views =
      plays > 1 ? strings.video.viewPlural : strings.video.viewSingular;

    return `${formatted} ${views}`;
  }, [plays]);

  const timeText = useMemo(() => {
    return moment(createdOn).fromNow();
  }, [createdOn]);

  const durationText = useMemo(() => {
    return formatTimeStamp(duration.toString());
  }, [duration]);

  const profileUrl = useMemo(() => {
    return getProfileUrl(username);
  }, [username]);

  const stopPropagationOnClick: MouseEventHandler<any> = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const videoItemOnClick = useCallback(() => {
    onClick(index);
  }, [index, onClick]);

  const deleteOnClick = useCallback(() => {
    if (openDeleteConfirmation) {
      openDeleteConfirmation(externalId);
    }
  }, [externalId, openDeleteConfirmation]);

  const unlikeOnClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.stopPropagation();

      if (openUnlikeModal) {
        openUnlikeModal(externalId);
      }
    },
    [externalId, openUnlikeModal]
  );

  const verticalVisibilityOffset = useMemo(() => {
    return {top: VISIBILITY_OFFSET, bottom: VISIBILITY_OFFSET};
  }, []);

  return {
    deleteOnClick,
    durationText,
    profileUrl,
    timeText,
    stopPropagationOnClick,
    unlikeOnClick,
    verticalVisibilityOffset,
    videoItemOnClick,
    viewsText
  };
}
