import styled from "styled-components";
import Text from "components/Atoms/Text";
import {errorRed} from "config/styleVars";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 480px;
  width: 100%;
  margin-top: 48px;
  padding: 0 12px;
`;

export const Header = styled(Text)`
  margin-bottom: 48px;
`;

export const FormItemContainer = styled.div`
  margin-bottom: 22px;
`;

export const Error = styled.p`
  font-size: 0.8em;
  color: ${errorRed};
  margin: 8px 0;
`;
