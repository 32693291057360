import {useCallback, useEffect, useMemo, useState} from "react";
import {MetadataFormValues} from "components/Organisms/MetadataForm/types";
import {ThumbnailType} from "components/Organisms/ThumbnailForm/types";
import {SCRUBBER_INITIAL_END, SCRUBBER_INITIAL_START} from "config/constants";
import {
  useGenerateThumbnailFromVideoLazyQuery,
  useUpdateVideoMutation
} from "apollo";

export function useUploadVideoAndMetadata(onSuccess: () => void) {
  const [meta, setMeta] = useState<MetadataFormValues | null>(null);
  const [externalId, setExternalId] = useState<string>();
  const [isVideoUploaded, setIsVideoUploaded] = useState<boolean>(false);
  const [metaSaved, setMetaSaved] = useState(false);
  const [clippingSaved, setClippingSaved] = useState(false);
  const [thumbnailType, setThumbnailType] = useState<ThumbnailType>(
    ThumbnailType.Clipping
  );
  // clipping tool
  const [startTime, setStartTime] = useState<number>(SCRUBBER_INITIAL_START);
  const [endTime, setEndTime] = useState<number>(SCRUBBER_INITIAL_END);
  // thumbnail upload
  const [isThumbnailUploaded, setIsThumbnailUploaded] =
    useState<boolean>(false);

  const onUploadCompleted = useCallback(() => {
    setIsVideoUploaded(true);
  }, []);

  const isMetaValid = useMemo(() => {
    return meta?.description && meta?.title && meta?.tags?.length;
  }, [meta]);

  const [updateVideoMeta, {loading: metaLoading}] = useUpdateVideoMutation({
    onCompleted: () => {
      setMetaSaved(true);
    }
  });

  const [updateClippingData, {loading: clippingLoading}] =
    useGenerateThumbnailFromVideoLazyQuery({
      onCompleted: () => {
        setClippingSaved(true);
      }
    });

  const finishButtonDisabled = useMemo(() => {
    if (!isVideoUploaded || !isMetaValid) {
      return true;
    }

    // selected thumbnail upload and not yet uploaded
    if (thumbnailType === ThumbnailType.Upload && !isThumbnailUploaded) {
      return true;
    }

    // currently saving data
    return metaLoading || clippingLoading;
  }, [
    clippingLoading,
    isMetaValid,
    isThumbnailUploaded,
    isVideoUploaded,
    metaLoading,
    thumbnailType
  ]);

  const finishOnClick = useCallback(() => {
    if (!meta) return;

    const metaPayload = {
      externalId,
      title: meta.title,
      description: meta.description,
      tags: meta.tags.join(", ")
    };
    updateVideoMeta({variables: metaPayload}).catch((e) => {
      console.log("meta update failed", e);
    });

    if (thumbnailType === ThumbnailType.Clipping) {
      const clippingPayload = {
        externalId,
        start: startTime,
        end: endTime
      };
      updateClippingData({variables: clippingPayload});
    }
  }, [
    endTime,
    externalId,
    meta,
    startTime,
    thumbnailType,
    updateClippingData,
    updateVideoMeta
  ]);

  // watch relevant query success states and call success when everything is done
  useEffect(() => {
    if (metaSaved && (isThumbnailUploaded || clippingSaved)) {
      onSuccess();
    }
  }, [clippingSaved, isThumbnailUploaded, metaSaved, onSuccess]);

  return {
    clippingLoading,
    endTime,
    externalId,
    finishButtonDisabled,
    finishOnClick,
    isThumbnailUploaded,
    isVideoUploaded,
    meta,
    metaLoading,
    onUploadCompleted,
    setEndTime,
    setExternalId,
    setIsThumbnailUploaded,
    setMeta,
    setStartTime,
    setThumbnailType,
    startTime,
    thumbnailType
  };
}
