import * as constants from "config/constants";

export const strings = {
  auth: {
    alreadyRegistered: "Already have an account?",
    codeLabel: "6-digit code",
    codeSentSuccess:
      "A new code sent to your email and should arrive in 1-5 minutes. Please check your email.",
    confirmPassword: "Confirm Password",
    confirmPasswordRequired: "You must confirm your password.",
    emailCodeButton: "Email recovery code",
    emailInvalid: "Please enter a valid email.",
    emailRequired: "Email is required.",
    usernameInvalid: "Please enter a valid username.",
    usernameRequired: "Username is required.",
    nameInvalid: "Please enter a valid name.",
    nameRequired: "Name is required.",
    enterCode: (
      email: string
    ) => `Please enter the 6-digit code that was sent to ${email} in order to
    verify your email address.`,
    forgotPassHeading: "Forgot Password",
    forgotPassSubHeading: "Don't worry, it happens to the best of us.",
    forgotPassword: "Forgot Password?",
    newPassword: "New Password",
    newPasswordRequired: "New password is required.",
    noAccount: "Don't have an account? ",
    noCode: "Didn't receive a code?",
    noEmail: "Didn't reeive an email?",
    passwordError:
      "Passwords must be at least 8 characters long, contain a number, and have both uppercase and lowercase characters.",
    passwordLength: (min: number) =>
      `Password must be ${min} characters or longer.`,
    passwordLowercase: "Password must contain one lowercase character.",
    passwordMismatch: "Passwords do not match!",
    passwordNumber: "Password must contain one number.",
    passwordRequired: "Password is required.",
    passwordResetSuccess:
      "Password reset success! Please sign in with the new password.",
    passwordUppercase: "Password must contain one uppercase character.",
    resendCodeButton: "Resend Code",
    resetPassword: "Reset Password",
    resetPasswordSubHeading:
      "If your account exists, an email has been sent with a reset code. Please enter the code and a new password when you get it.",
    signIn: "Sign In",
    signOut: "Sign Out",
    signUp: "Sign Up",
    joinWaitlist: "Join the Waitlist",
    signUpSuccess: "You have successfully signed up! You may now log in.",
    userFetchError: "Error retrieving user profile. Please try again.",
    verificationCodeRequired: "6-digit verification code is required."
  },
  cropping: {
    subheading: "Choose a file and click save to upload your ",
    error: "Error uploading your image. Please try again later."
  },
  dropzone: {
    dndThumbnail: "Drag and drop a thumbnail here",
    dndVideo: "Drag and drop a video here",
    or: "Or ",
    browse: "browse",
    toChoose: " to choose a file"
  },
  generic: {
    areYouSure: "Are you sure?",
    unlikeConfirmation:
      "Are you sure you want to unlike this post?\nThis action cannot be undone.",
    avatar: "Avatar",
    banner: "Banner",
    birthday: "Birthday",
    bio: "Bio",
    back: "Back",
    cancel: "Cancel",
    close: "Close",
    complete: "Complete",
    cropping: "cropping",
    delete: "Delete",
    description: "Description",
    email: "Email",
    error: "Error",
    fileName: "File Name",
    following: "Following",
    followers: "Followers",
    forYou: "For You",
    getTheApp: "Get the App",
    home: "Home",
    likes: "Likes",
    like: "Like",
    location: "Location",
    ok: "Ok",
    unlike: "Unlike",
    goBack: "Go Back",
    username: "Username",
    password: "Password",
    name: "Name",
    save: "Save",
    settings: "Settings",
    submit: "Submit",
    tag: "Tag",
    tags: "Tags",
    thumbnail: "Thumbnail",
    title: "Title",
    upload: "Upload",
    verify: "Verify",
    video: "Video",
    watchNow: "Watch Now",
    requiredField: "This field is required",
    follow: "Follow",
    cancelEditing: "Cancel Editing?",
    discardChanges: "Discard Changes",
    continueEditing: "Continue Editing",
    send: "Send",
    website: "Website",
    mintNFTs: "Mint NFTs",
    marketplace: "Marketplace",
    minute: "m",
    second: "s"
  },
  pages: {
    error: {
      bodyParagraph: "Sorry, we can't find what you're looking for.",
      title: "Error 404",
      goBack: "Go back home"
    },
    following: {
      title: "Following"
    },
    feed: {
      explore: "Explore",
      feedErrorMessage:
        "Uh-oh... There seems to be problems with the internet connection and now you’re floating in the eVerse universe!"
    },
    home: {
      bodyParagraph: "Welcome to eVerse.",
      title: "Home Page"
    },
    player: {
      noFollowingVideos: "You aren't following anyone with videos yet.",
      stopPlayer: {
        header: "The player stopped",
        body: "The player stopped because you have not interacted in a while.\nClose this dialog and continue playing."
      }
    },
    profile: {
      title: "Profile Page",
      editProfile: "Edit Profile",
      edit: "Edit",
      imageTypeSuggestion:
        "Your image must be\nJPG, PNG, or GIF\nUnder 1GB in Size",
      imageSizeSuggestion: "We Suggest\n16:9",
      userTooYoung: "You must be at least 13 years of age to sign up.",
      userTooOld: "You can't be that old.",
      birthDateInvalid: "Birthday should be in format MM/DD/YYYY.",
      locationTooLong:
        "The location you entered is too long. Try removing some characters.",
      websiteTooLong:
        "The website you entered is too long. Try removing some characters.",
      urlNoHttp: "Please ensure your URL begins with http or https.",
      bioTooLong:
        "The bio you entered is too long. Try removing some characters.",
      nameTooShort: "Please enter a name between 3 and 25 characters long.",
      nameTooLong: "Please enter a name between 3 and 25 characters long.",
      usernameEmptyError:
        "Please enter a username between 3 and 25 characters long.",
      usernameTooLong:
        "Please enter a username between 3 and 25 characters long.",
      posts: "Posts",
      likedPosts: "Liked Posts",
      collectibles: "Collectibles",
      noPosts: "You haven't posted anything yet.",
      userNoPosts: "This creator hasn't posted anything yet.",
      collectiblesComingSoon: "Coming Soon...",
      noLikes: "You haven't liked anything yet.",
      userNoLikes: "This creator hasn't liked anything yet.",
      error: "Error fetching videos.",
      changeUsername: "Change Username",
      usernameConstraints:
        "Usernames can contain only letters, numbers, underscores, and periods.",
      previousName: "Previous username",
      currentUsername: "Current Username",
      previousNameInfo:
        "Your previous username has been held for 14 days in case you change your mind. After {0} days, it will become available for someone else to claim.",
      changeBack: "Change Back",
      userListError: "Error fetching users.",
      noFollowing: "You haven't followed anyone yet",
      noFollowingPublic:
        "The creator isn’t following anyone yet -  All the accounts the creator is following will show up here.",
      noFollowers: "No one is following you yet.",
      noFollowersPublic:
        "The creator doesn’t have any followers yet - All the accounts that follow the creator will show up here.",
      tryAgainError: "Something went wrong. Please try again later.",
      changeBackError: "Failed to change name.",
      usernameReverted:
        "Your username has been successfully reverted back to @{0}!",
      usernameChanged: "Your username has been successfully changed to @{0}!",
      unsavedChanges:
        "You have unsaved changes to your profile that will no be saved. Are you sure you want to Cancel?"
    },
    settings: {
      title: "Settings",
      accounts: "ACCOUNTS",
      general: "GENERAL",
      manageAccount: "Manage account",
      manageAccountTitle: "Manage Account",
      help: "Help",
      sendFeedback: "Send Feedback",
      about: "About",
      privacy: "Privacy",
      wallet: "Wallet",
      tos: "Terms of Service",
      logout: "Log Out",
      messageLogout: "Are you sure you want to log out?",
      personalInformation: "PERSONAL INFORMATION",
      personalDisclaimer:
        "All personal information will be kept private and will not be displayed on your public profile.",
      password: "Password",
      change: "Change",
      changePassword: "Change Password",
      passwordDisclaimer: `If you want to change your password, click on the Reset password button. We will send a reset code to your email `,
      resetPassword: "Reset Password",
      getInTouch: "Get in touch",
      helpDisclaimer: {
        mailto: "Please email ",
        contactSupport:
          "to contact support. We will respond as soon as possible.",
        email: "support@everse.tv "
      },
      emailModalTitle: "Email Sent",
      emailModalDisclaimer: (email: string) =>
        `An email has been sent to ${email} with instructions for changing your password`
    },
    upload: {
      videoUpload: "Video Upload",
      descriptionRequired: "Description is required",
      differentVideo: "Select a different Video",
      headingInitial: (username: string) => `👋 Hi ${username}. Welcome back!`,
      headingSuccess: "🎉 Hooray!",
      instructionBottom: {
        heading: "We suggest",
        one: "16:9 aspect ratio",
        three: "Max 60fps",
        two: "Max 5min in length"
      },
      instructionTop: {
        heading: "We recommend",
        one: "MP4 or MOV videos",
        two: "Under 1GB in size"
      },
      maxCharacters: "Max character limit exceeded",
      notEnoughTags: (min: number) =>
        `You must add at least ${min} tag${min > 1 ? "s" : ""}.`,
      noThumbnail: "You must add a thumbnail",
      noVideo: "Error: no video selected",
      subHeadingInitial:
        "Videos you upload here will show up in the mobile app.",
      subheadingSuccess: {
        one: "Your video was successfully uploaded!",
        two: "What would you like to do next?"
      },
      successPlusSection: {
        caption: "Want to upload another video?",
        button: "Click to upload another!"
      },
      successHomeSection: {
        caption: "Want to view your feed?",
        button: "Take me to the homepage!"
      },
      successUserSection: {
        caption: "Want to view your new video?",
        button: "Head to your profile!"
      },
      tagConfigError: "Error configuring min & max during tag validation.",
      uploadOwnThumbnail: "Upload a Thumbnail",
      goBackToClipping: "Go back to use clipping tool",
      thumbnailUploadError: "Error uploading thumbnail.",
      thumbnailURLError: "Error getting thumbnail upload URL.",
      thumbnailGettingVideoInfo: "Getting video info.",
      thumbnailProcessingHeader: "Hang tight!",
      thumbnailProcessing: "Your thumbnail clip is still processing.",
      thumbnailLegend:
        "Thumbnail preview may look pixilated to aid in preview performance. The actual thumbnail post will be of higher quality.",
      titleRequired: "Title is required.",
      tooManytags: (count: number) =>
        `You have added too many tags. Remove ${count} tags to continue.`,
      uploadingHeading: "Please wait while the video is being uploaded...",
      uploadVideoButton: "Upload Video",
      videoURLError: "Error getting video upload url.",
      thumbnail: {
        preview: "Error loading preview",
        uploadThumbError:
          "Thumbnail did not upload successfully. Please try again."
      },
      videoUploadError: "Error uploading video",
      fileFormatUploadError: "Please select .mp4 or .mov",
      validators: {
        fileRequired: "A file is required",
        fileTooLarge: "File too large",
        videoUpload: {
          fileFormat: "Please select a supported file format."
        },
        metaUpload: {
          required: "at least one tag is required",
          max: "only a max of 30 tags are allowed"
        },
        thumbnailUpload: {
          fileFormat: "Please select a GIF file."
        },
        imageUpload: {
          fileFormat: "Please select a JPG, JPEG, GIF or PNG file."
        }
      },
      createThumbnail: "Create a Thumbnail",
      videoDetails: "Video Details",
      enterTag: "Enter hashtag and press space",
      uploading: "Uploading...",
      finish: "Finish"
    },
    onboarding: {
      title: "Welcome to eVerse",
      subHeader: "Let's get started.",
      nameError: `Please enter a valid name between ${constants.NAME_MIN_LENGTH} and ${constants.NAME_MAX_LENGTH} characters long.`,
      usernameEmpty: `Please enter a username between ${constants.USERNAME_MIN_LENGTH} and ${constants.USERNAME_MAX_LENGTH} characters long`,
      usernameCharacters:
        "Only letters, numbers, period, and underscore are allowed.",
      usernameTaken: "Sorry, that username is already taken.",
      birthdayEmpty: "Please enter your birthday.",
      birthdayInvalid: `You must be at least ${constants.AGE_MIN} years old to join eVerse.`,
      submitError: "Error saving profile."
    },
    mintNFTs: {
      title: "Create & Sell your NFTs",
      step1: "Step 1",
      step1Title: "Create Your Account",
      step1Description:
        "Start by creating an account on eVerse, validating your email address and then sign in to complete your profile.",
      step2: "Step 2",
      step2Title: "Upload a Video",
      step2Description:
        "Upload one or more videos for which you own the creative license.",
      step3: "Step 3",
      step3Title: "Mint your NFT",
      step3Description:
        "Click the 'Get Started' button below and then tell us which video you want to have minted. You'll need to answer a few questions.",
      step4: "Step 4",
      step4Title: "Sell your NFT",
      step4Description:
        "We will cover the costs of minting your NFTs. You'll recieve an email notication from us once you NFT is listed on OpenSea.",
      getStartedButton: "Get Started"
    },
    marketplace: {
      discover: "Discover, find,",
      create: "Collect Original NFTs",
      description: `Buy and trade video-based NFTs. Some of these NFTs will come with revenue sharing rights. \nFor the first time, video creators can share revenue rights directly with fans, \nbuilding a new revenue stream that empowers them to retain control over their work. `,
      marketplace: "Marketplace",
      comingSoon: "Coming Soon"
    }
  },
  tags: {
    full: "Remove a tag to add a new one.",
    howToAdd: "Enter a tag and press 'space'.",
    removeTag: "Remove Tag"
  },
  video: {
    videoNotFound: "Whoops, This video isn't available anymore",
    thumbnailError: "Failed to load thumbnail",
    thumbnailProcessing:
      "Thumbnail is still processing. Refresh the page to try again.",
    displayTitleError: "Whoops, something went wrong...",
    displaySubtitleError:
      "eVerse was unable to load the video. Please try again later or select a different post.",
    commentPlaceholder: "Add a Comment...",
    noCommentsHeader: "No Comments yet",
    noCommentsBody:
      "This video doesn't have any comments yet. Be the first to post a comment!",
    commentFailed: "Failed to send. Please try later.",
    viewSingular: "view",
    viewPlural: "views"
  },
  search: {
    moreResults: (count: number) => `More Results (${count})`,
    search: "Search",
    clear: "Clear",
    profiles: "Profiles",
    noResults: "No Search Results",
    noResultsQuery: (query: string) =>
      `There are no search results for '${query}'.`,
    checkSpelling: "Check the spelling of the user",
    results: (count: number) => `${count} result${count > 1 ? "s" : ""}`
  }
};
