import React from "react";
import ClippingTool from "components/Organisms/ClippingTool";
import ThumbnailUpload from "components/Organisms/ThumbnailUpload";
import {TextTypes} from "components/Atoms/Text/types";
import {strings} from "config/strings";

import {
  Container,
  Header,
  SwitchThumbnailTypeButton,
  TextDivider
} from "./styles";
import {ThumbnailFormProps, ThumbnailType} from "./types";
import {useThumbnailForm} from "./hooks";

const ThumbnailForm: React.FC<ThumbnailFormProps> = (
  props: ThumbnailFormProps
) => {
  const {
    endTime,
    externalId,
    isVideoUploaded,
    setEndTime,
    setIsThumbnailUploaded,
    setStartTime,
    thumbnailType,
    startTime,
    uuid,
    video
  } = props;
  const {setThumbnailTypeClipping, setThumbnailTypeUpload} =
    useThumbnailForm(props);

  return (
    <Container>
      <Header>
        <SwitchThumbnailTypeButton
          isSelected={thumbnailType === ThumbnailType.Clipping}
          onClick={setThumbnailTypeClipping}
          textType={TextTypes.SubHeadings.SH3}
        >
          {strings.pages.upload.createThumbnail}
        </SwitchThumbnailTypeButton>
        <TextDivider textType={TextTypes.SubHeadings.SH3}>/</TextDivider>
        <SwitchThumbnailTypeButton
          isSelected={thumbnailType === ThumbnailType.Upload}
          onClick={setThumbnailTypeUpload}
          textType={TextTypes.SubHeadings.SH3}
        >
          {strings.pages.upload.uploadOwnThumbnail}
        </SwitchThumbnailTypeButton>
      </Header>
      <ClippingTool
        endTime={endTime}
        externalId={externalId}
        isVisible={thumbnailType === ThumbnailType.Clipping}
        isVideoUploaded={isVideoUploaded}
        setEndTime={setEndTime}
        setStartTime={setStartTime}
        startTime={startTime}
        video={video}
      />
      <ThumbnailUpload
        externalId={externalId}
        isVisible={thumbnailType === ThumbnailType.Upload}
        setIsThumbnailUploaded={setIsThumbnailUploaded}
        uuid={uuid}
      />
    </Container>
  );
};

export default React.memo(ThumbnailForm);
