const primaryColors = {
  cyan: "rgba(66,255,254,1)",
  cyan50: "rgba(66,255,254,0.5)",
  white: "rgba(255,255,255,1)",
  white10: "rgba(255,255,255,0.1)",
  white16: "rgba(255,255,255,0.16)",
  lightGrey: "rgba(163,158,169,1)",
  mediumGrey: "rgba(113,110,117,1)",
  mediumGrey40: "rgba(113,110,117,0.4)",
  darkGrey: "rgba(75,73,77,1)",
  black: "rgba(0,0,0,1)",
  black50: "rgba(0,0,0,0.5)"
};

const secondaryColors = {
  success: "rgba(76,175,80,1)",
  error: "rgba(249,31,167,1)"
};

const gradientColors = {
  plum: "linear-gradient(170.28deg, #4F71F1 0.52%, #863EE2 102.13%)",
  space:
    "linear-gradient(204.9deg, #47F2F0 29.52%, #EBCBEF 56.59%, #B7C3F4 67.25%, #4CBCFE 91.25%)",
  shadowDown:
    "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%)",
  shadowUp:
    "linear-gradient(180deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%)",
  mint: "linear-gradient(180deg, #96BDD3 55.21%, #6D767B 100%)",
  air: "linear-gradient(225deg, #3AF2F1 0%, #E1F7EF 18.75%, #FDFFDA 33.33%, #FFC9FF 55.73%, #4CBCFE 100%)"
};

const messageBackgroundsColors = {
  info: "rgba(0,21,41,1)",
  success: "rgba(12,28,13,1)",
  error: "rgba(37,13,12,1)"
};

export const colors = {
  primary: primaryColors,
  secondary: secondaryColors,
  gradients: gradientColors,
  messageBackgrounds: messageBackgroundsColors
};
