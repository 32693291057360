import styled from "styled-components";
import TextButton from "components/Atoms/TextButton";
import Text from "components/Atoms/Text";
import {accentColor, textColor} from "config/styleVars";

export const Container = styled.div`
  max-width: 480px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  padding: 0 12px;
`;

export const Header = styled.div`
  margin-bottom: 48px;
  display: flex;
`;

export const SwitchThumbnailTypeButton = styled(TextButton)<{
  isSelected: boolean;
}>`
  color: ${(props) => (props.isSelected ? accentColor : textColor)};

  &:hover {
    transform: scale(1.03);
  }
`;

export const TextDivider = styled(Text)`
  margin: 0px 12px;
`;
