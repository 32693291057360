import {differenceInSeconds} from "date-fns";
import {strings} from "../config/strings";

export const formatTimeStamp = (time?: string) => {
  if (time === undefined) return "";

  const formattedTime = parseInt(time, 10);
  const minutes = formattedTime > 0 ? Math.floor(formattedTime / 60) : 0;
  let seconds = Math.floor(formattedTime - minutes * 60);
  seconds = seconds >= 0 ? seconds : 0;
  return `${minutes.toString().padStart(1, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;
};

export const formatTimeLapse = (time?: number) => {
  if (time === undefined) return "";

  const minutes = ~~((time % 3600) / 60);
  const seconds = ~~Math.ceil(time) % 60;

  return `${minutes.toString().padStart(1, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;
};

const lookup = [
  {value: 1, symbol: ""},
  {value: 1e3, symbol: "k"},
  {value: 1e6, symbol: "M"}
];

export const formatCountNumber = (num: number, digits = 1): string => {
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((value) => {
      return num >= value.value;
    });

  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
};

const intervals = [
  {time: 31536000, label: "y"},
  {time: 2592000, label: "mo"},
  {time: 86400, label: "d"},
  {time: 3600, label: "h"},
  {time: 60, label: "m"}
];

export const formatTimeDistance = (time: string, date?: Date) => {
  const presentDay = !date ? new Date() : date;
  const seconds = differenceInSeconds(presentDay, new Date(time));

  let label = "";

  // eslint-disable-next-line no-restricted-syntax
  for (const el of intervals) {
    const interval = Math.floor(seconds / el.time);

    if (interval >= 1) {
      label = `${interval}${el.label}`;
      break;
    }
  }

  return label || `${Math.floor(seconds)}s`;
};

// takes in date string from server in the format yyyy-mm-dd and returns a date
// with current timezone for display purposes.
export const addTZ = (timeIn: string) => {
  const fields = timeIn.split("-");
  const timeOut = new Date();
  timeOut.setFullYear(parseInt(fields[0], 10));
  timeOut.setMonth(parseInt(fields[1], 10) - 1);
  timeOut.setDate(parseInt(fields[2], 10));
  return timeOut;
};

export const formatTimelineTime = (seconds: number) => {
  if (seconds < 60) {
    return `${seconds}${strings.generic.second}`;
  }

  const minutes = Math.floor(seconds / 60);
  return `${minutes}${strings.generic.minute}${seconds % 60}${
    strings.generic.second
  }`;
};

const formatTimelineSelectedTime = (timeSeconds: number) => {
  const minutes = Math.floor(timeSeconds / 60);
  let seconds: string | number = Math.round((timeSeconds % 60) * 10) / 10;
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }
  return `${minutes}:${seconds}`;
};

export const formatTimelineInterval = (
  startTimeSeconds: number,
  endTimeSeconds: number
) => {
  return `${formatTimelineSelectedTime(
    startTimeSeconds
  )} - ${formatTimelineSelectedTime(endTimeSeconds)}`;
};
