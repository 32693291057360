import {FeedVideo} from "apollo";
import Avatar from "components/Avatar";
import {formatTimeDistance, formatTimeStamp} from "utils/FormatUtils";
import {strings} from "config/strings";
import {Link} from "react-router-dom";
import {getProfileUrl} from "config/routes";
import {DropMenu, DropMenuItem} from "components/DropMenu";
import {Spinner} from "components/Spinner";

import UnlikeIcon from "icons/16/x.svg";
import DeleteIcon from "icons/16/trash-2.svg";
import {useCountFormat} from "hooks/useCountFormat";
import {
  Meta,
  Thumb,
  ThumbImage,
  Sizer,
  MetaTitle,
  MetaDuration,
  ErrorText,
  ContextMenu,
  UnlikeButton,
  MetaClickArea,
  MetaViews,
  MetaViewsNumber,
  MetaInfo,
  MetaNumberDistance,
  MetaAdditional
} from "./ThumbnailStyles";

type ThumbnailProps = {
  video: FeedVideo;
  index: number;
  onClick?: (value: number) => void;
  showProfilePostsMenu?: boolean;
  deleteFn?: (externalId: string) => void;
  showUnlikeButton?: boolean;
  unlikeFn?: (externalId: string) => void;
};

export const Thumbnail: React.FC<ThumbnailProps> = ({
  video,
  index,
  onClick,
  showProfilePostsMenu,
  deleteFn,
  showUnlikeButton,
  unlikeFn
}: ThumbnailProps) => {
  const handleClick = () => {
    if (video && onClick) {
      onClick(index);
    }
  };

  const isNewVideo = (createdOn: string) => {
    const newVidTime = 5 * 60000; // 5 min
    const nowTime = new Date().getTime();
    const vidTime = new Date(createdOn).getTime();
    return nowTime - vidTime <= newVidTime;
  };

  const getThumbnailError = (createdOn: string) => {
    if (isNewVideo(createdOn)) {
      return strings.video.thumbnailProcessing;
    }
    return strings.video.thumbnailError;
  };

  return (
    <Thumb>
      {video.thumbnailUrl && (
        <ThumbImage
          style={{backgroundImage: `url(${video.thumbnailUrl})`}}
          onClick={handleClick}
        />
      )}
      {!video.thumbnailUrl && (
        <ErrorText
          processing={isNewVideo(video.createdOn)}
          onClick={handleClick}
        >
          {isNewVideo(video.createdOn) && <Spinner />}
          {getThumbnailError(video.createdOn)}
        </ErrorText>
      )}
      <Meta>
        {/* eslint-disable-next-line react-extra/no-inline-styles -- MAR-844 */}
        <div style={{position: "absolute", top: "10px", left: "0px"}}>
          <Avatar
            avatarURL={video.avatar}
            size={30}
            username={video.username}
            uuid={video.userUuid}
          />
        </div>
        <MetaClickArea onClick={handleClick} />
        <MetaTitle>{video.title}</MetaTitle>
        <MetaInfo>
          <Link to={getProfileUrl(video.username)}>@{video.username}</Link>
          <MetaViews>
            <MetaViewsNumber>{useCountFormat(video.plays)}</MetaViewsNumber>
            {video.plays > 0 && <MetaAdditional> views</MetaAdditional>}
            <MetaNumberDistance>
              {formatTimeDistance(video.createdOn)}
            </MetaNumberDistance>
            <MetaAdditional> ago</MetaAdditional>
          </MetaViews>
          {video.properties && (
            <MetaDuration>
              {formatTimeStamp(video.properties?.duration?.toString())}
            </MetaDuration>
          )}
        </MetaInfo>
      </Meta>
      {showProfilePostsMenu && deleteFn && (
        <ContextMenu>
          <DropMenu>
            <DropMenuItem
              clickFn={() => deleteFn(video.externalId)}
              iconPath={DeleteIcon}
              label={strings.generic.delete}
            />
          </DropMenu>
        </ContextMenu>
      )}
      {showUnlikeButton && unlikeFn && (
        <UnlikeButton
          title={strings.generic.unlike}
          onClick={() => unlikeFn(video.externalId)}
        >
          <img alt="Unlike icon" src={UnlikeIcon} />
        </UnlikeButton>
      )}
      <Sizer />
    </Thumb>
  );
};
