import React from "react";
import Avatar from "components/Avatar";
import Text from "components/Atoms/Text";
import {TextTypes} from "components/Atoms/Text/types";
import {ReactComponent as SettingsIcon} from "icons/24/settings.svg";
import {ReactComponent as WalletIcon} from "icons/wallet.svg";
import {ReactComponent as SignOutIcon} from "icons/24/logout.svg";
import {ReactComponent as SendFeedbackIcon} from "icons/24/message-square.svg";
import {strings} from "config/strings";
import UserMenuItem from "components/TopNavigation/UserMenu/UserMenuItem";
import {Routes} from "config/routes";
import DownArrowIcon from "icons/24/chevron-down.svg";
import {UserMenuProps} from "./types";
import {
  Divider,
  DropMenu,
  ManageProfileLink,
  ProfileLink,
  RootContainer,
  UserContainer,
  UserTextContainer
} from "./styles";
import {useUserMenu} from "./hooks";

const UserMenu: React.FC<UserMenuProps> = ({user}: UserMenuProps) => {
  const {
    editProfileUrl,
    profileUrl,
    settingsUrl,
    signOutOnClick,
    walletOnClick
  } = useUserMenu(user);
  if (!user) {
    return null;
  }

  return (
    <RootContainer>
      <Avatar
        avatarURL={user.avatar!}
        size={40}
        username={user.username!}
        uuid={user.uuid!}
      />
      <img src={DownArrowIcon} alt="down icon" className="arrowIcon" />
      <DropMenu>
        <UserContainer>
          <Avatar
            avatarURL={user.avatar!}
            size={40}
            username={user.username!}
            uuid={user.uuid!}
          />
          <UserTextContainer>
            <ProfileLink to={profileUrl}>
              <Text
                textType={TextTypes.SubHeadings.SH2}
              >{`@${user.username}`}</Text>
            </ProfileLink>
            <div data-cy="manage-account-button">
              <ManageProfileLink to={editProfileUrl}>
                <Text textType={TextTypes.Body}>Manage account</Text>
              </ManageProfileLink>
            </div>
          </UserTextContainer>
        </UserContainer>
        <Divider />
        <UserMenuItem
          onClick={walletOnClick}
          text={strings.pages.settings.wallet}
        >
          <WalletIcon width={20} height={20} />
        </UserMenuItem>
        <UserMenuItem text={strings.generic.settings} to={settingsUrl}>
          <SettingsIcon width={20} height={20} />
        </UserMenuItem>
        <UserMenuItem
          text={strings.pages.settings.sendFeedback}
          to={Routes.settingsHelp}
        >
          <SendFeedbackIcon width={20} height={20} />
        </UserMenuItem>
        <Divider />
        <UserMenuItem onClick={signOutOnClick} text={strings.auth.signOut}>
          <SignOutIcon width={20} height={20} />
        </UserMenuItem>
      </DropMenu>
    </RootContainer>
  );
};

export default React.memo(UserMenu);
