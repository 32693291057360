import {FeedVideo, useGetPublishedVideoQuery} from "apollo";
import {Spinner} from "components/Spinner";
import Player from "components/Video/Player";
import {INITIAL_ITEMS_COUNT} from "config/constants";
import {getFeedPlayerUrl} from "config/routes";
import {useFeed} from "hooks/useFeed";
import {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router";
import ErrorPage from "pages/ErrorPage";

const FeedPlayer = () => {
  const feedData = useFeed(true);
  const {videoId} = useParams<{videoId: string}>();
  const history = useHistory();
  const [index, setIndex] = useState(0);
  const [videos, setVideos] = useState<FeedVideo[]>();
  const time = new URLSearchParams(history.location.search).get("time") || "";

  const {
    data: videoData,
    loading: isLoading,
    error
  } = useGetPublishedVideoQuery({
    variables: {
      externalId: videoId
    },
    skip: index > 0
  });

  useEffect(() => {
    if (videoData?.getPublishedVideo && !videos && feedData?.videos) {
      const videoIndex = feedData?.videos?.findIndex(
        (x) => x.externalId === videoId
      );
      if (videoIndex >= 0) {
        setIndex(videoIndex);
        setVideos(feedData.videos.slice(0));
        return;
      }
      const newVideo = videoData?.getPublishedVideo as FeedVideo;
      setVideos([newVideo, ...feedData?.videos.slice(0)]);
    }
  }, [feedData?.videos, videoData?.getPublishedVideo, videoId, videos]);

  const handleVideoChange = async (newIndex: number) => {
    if (feedData?.videos && videos) {
      const shouldLoadNextPage =
        feedData?.count &&
        newIndex < feedData?.count &&
        newIndex >= videos.length;
      const shouldMoveInsidePage =
        feedData?.count && newIndex < feedData?.count && newIndex >= 0;

      if (shouldLoadNextPage) {
        const {data} = await feedData.fetchMore({
          variables: {
            page: {
              take:
                INITIAL_ITEMS_COUNT +
                Math.floor(newIndex / INITIAL_ITEMS_COUNT) * INITIAL_ITEMS_COUNT
            }
          }
        });

        setVideos([...((data?.getFeed?.videos as Array<FeedVideo>) || [])]);
        const video = data?.getFeed?.videos?.[newIndex];
        const url = getFeedPlayerUrl(video?.externalId!);
        history.push(url);
      } else if (shouldMoveInsidePage) {
        setIndex(newIndex);
        const url = getFeedPlayerUrl(videos[newIndex].externalId);
        history.push(url);
      }
    }
  };

  return (
    <>
      {isLoading && <Spinner />}
      {!isLoading && !error && videos && (
        <Player
          handleVideoChange={handleVideoChange}
          time={time}
          videoIndex={index}
          videos={videos}
        />
      )}
      {error && <ErrorPage />}
    </>
  );
};

export default FeedPlayer;
