import * as Yup from "yup";
import {strings} from "../../config/strings";

const IMAGE_FILE_SIZE = 8 * 128 * 1024 * 50; // 50MB
const IMAGE_SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png"
];
const THUMB_SUPPORTED_FORMATS = ["image/gif"];
const VIDEO_FILE_SIZE = 8 * 128 * 1024 * 1024 * 8; // 1GB
const VIDEO_SUPPORTED_FORMATS = [
  "video/mp4",
  "video/mpeg",
  "video/ogg",
  "video/quicktime",
  "application/vnd.apple.mpegurl",
  "application/x-mpegurl",
  "video/3gpp",
  "video/webm",
  "video/x-m4v",
  "video/ms-asf",
  "video/x-ms-wmv",
  "video/x-msvideo"
];

export function imageUploadValidator() {
  return Yup.object().shape({
    file: Yup.mixed()
      .required(strings.pages.upload.validators.fileRequired)
      .test(
        "fileSize",
        strings.pages.upload.validators.fileTooLarge,
        (value) => {
          return value && value.size <= IMAGE_FILE_SIZE;
        }
      )
      .test(
        "fileFormat",
        strings.pages.upload.validators.imageUpload.fileFormat,
        (value) => value && IMAGE_SUPPORTED_FORMATS.includes(value.type)
      )
  });
}

export function thumbnailUploadValidator() {
  return Yup.object().shape({
    file: Yup.mixed()
      .required(strings.pages.upload.validators.fileRequired)
      .test(
        "fileSize",
        strings.pages.upload.validators.fileTooLarge,
        (value) => {
          return value && value.size <= IMAGE_FILE_SIZE;
        }
      )
      .test(
        "fileFormat",
        strings.pages.upload.validators.thumbnailUpload.fileFormat,
        (value) => value && THUMB_SUPPORTED_FORMATS.includes(value.type)
      )
  });
}

export function videoUploadValidator() {
  return Yup.object().shape({
    file: Yup.mixed()
      .required(strings.pages.upload.validators.fileRequired)
      .test(
        "fileSize",
        strings.pages.upload.validators.fileTooLarge,
        (value) => {
          return value && value.size <= VIDEO_FILE_SIZE;
        }
      )
      .test(
        "fileFormat",
        strings.pages.upload.validators.videoUpload.fileFormat,
        (value) => value && VIDEO_SUPPORTED_FORMATS.includes(value.type)
      )
  });
}

export const metaUploadValidator = Yup.object().shape({
  title: Yup.string().required(),
  description: Yup.string().required(),
  tags: Yup.mixed()
    .test(
      "required",
      strings.pages.upload.validators.metaUpload.required,
      (value) => {
        return value && value.length >= 1;
      }
    )
    .test("max", strings.pages.upload.validators.metaUpload.max, (value) => {
      return value && value.length <= 30;
    })
});
