import styled from "styled-components";
import {CLIPPING_TOOL_TIMELINE_OFFSET} from "config/constants";
import Text from "components/Atoms/Text";
import DraggableItemsContainer from "components/Molecules/DraggableItemsContainer";

export const RootContainer = styled.div`
  margin: 0 -12px;
  padding: 0 12px;
  overflow: hidden;
`;

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
  overflow: visible;
`;

export const SelectedTimeText = styled(Text)`
  margin: 24px 0;
  align-self: center;
`;

export const ItemsContainer = styled(DraggableItemsContainer)<{
  overflow: string;
}>`
  display: flex;
  position: relative;
  flex-direction: row;
  overflow-x: ${(props) => props.overflow};
  overflow-y: hidden;
  max-width: 100%;
  box-sizing: border-box;
  padding: 0 ${CLIPPING_TOOL_TIMELINE_OFFSET}px;

  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;
