import {useCallback} from "react";

import {ThumbnailFormProps, ThumbnailType} from "./types";

export function useThumbnailForm({
  setThumbnailType,
  thumbnailType
}: ThumbnailFormProps) {
  const setThumbnailTypeClipping = useCallback(() => {
    if (thumbnailType !== ThumbnailType.Clipping) {
      setThumbnailType(ThumbnailType.Clipping);
    }
  }, [setThumbnailType, thumbnailType]);

  const setThumbnailTypeUpload = useCallback(() => {
    if (thumbnailType !== ThumbnailType.Upload) {
      setThumbnailType(ThumbnailType.Upload);
    }
  }, [setThumbnailType, thumbnailType]);

  return {setThumbnailTypeClipping, setThumbnailTypeUpload};
}
