import {
  getProfileEditUrl,
  getProfileUrl,
  getSettingsUrl,
  Routes
} from "config/routes";
import {User} from "apollo";
import {useCallback} from "react";
import {useSignOutButton} from "components/Auth/SignOutButton/hooks";

export function useUserMenu(user: User | null) {
  const {signOutOnClick} = useSignOutButton();
  const editProfileUrl = getProfileEditUrl(user?.username!);
  const profileUrl = getProfileUrl(user?.username!);
  const settingsUrl = getSettingsUrl(Routes.settings, user?.uuid!);

  const walletOnClick = useCallback(() => {
    alert("Coming Soon...");
  }, []);

  return {
    editProfileUrl,
    profileUrl,
    settingsUrl,
    signOutOnClick,
    walletOnClick
  };
}
