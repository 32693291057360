import React from "react";
import {strings} from "config/strings";
import {TextInputStyled} from "./styles";

import {useAlgoliaSearchBox} from "./hooks";
import {AlgoliaSearchBoxProps, SearchBox} from "./types";

const AlgoliaSearchBox: React.FC<AlgoliaSearchBoxProps> = (
  props: AlgoliaSearchBoxProps
) => {
  const {onBlur, onFocus} = props;
  const {query, renderIcon, refine} = useAlgoliaSearchBox(props);

  return (
    <TextInputStyled
      id={SearchBox}
      initialValue={query}
      onChange={refine}
      onBlur={onBlur}
      onFocus={onFocus}
      placeholder={strings.search.search}
      renderIcon={renderIcon}
    />
  );
};

export default React.memo(AlgoliaSearchBox);
